import React from "react"
import Profile1 from "../../../assets/img/leader/ganesh.png"
import Profile2 from "../../../assets/img/leader/timothy.png"
import Profile3 from "../../../assets/img/leader/david.png"
import Profile4 from "../../../assets/img/leader/ben.png"
import Profile5 from "../../../assets/img/leader/rajesh.png"
import Profile6 from "../../../assets/img/leader/aravind.png"
import Profile7 from "../../../assets/img/leader/ram.png"
import SocialIcons from "../../../components/Icon/social-group"
import Styled from "./styled.module.css"
const Mission = () => {
  return (
    <section className={Styled.section}>
      <div className={`container ${Styled.grid}`}>
        <div className={Styled.titleWrapper}>
          <h2 className="sm-text bold">SENIOR LEADERSHIP</h2>
          <h1 className="md-title bold">
            Good professionals enabling success<br /> for both clients and staff
          </h1>
          <p className="lg-text">
            The most effective organizations have strong, bold leadership. At
            Integra, we fit this mold being guided by a proven management team
            with deep industry expertise.
          </p>
        </div>
        <div className={Styled.leaderWrapper}>
          <div className={Styled.card}>
            <header className={Styled.header}>
              <figure className={Styled.figure}>
                <img alt="Ganesh Ranganathan" src={Profile1}></img>
                {/* <caption>
                  <SocialIcons />
                </caption> */}
              </figure>
              <div className={Styled.cardTitleWrapper}>
                <h1 className="sm-title bold">Ganesh Ranganathan</h1>
                <h3 className="sm-text">Chairman</h3>
              </div>
            </header>
            <div className={Styled.textWrapper}>
              <p className="sm-text">
                His vision is to provide world class digital support systems and solutions to global clients. This vision is reflected in the quality and client focus that is emphasized across the company.
              </p>
              <p className="sm-text">
                He is responsible for strategic planning and has captained Integra since inception.
              </p>
              <p className="sm-text">
                His prior experience includes 20+ years of experience in Fortune 100 companies in various capacities across finance, operations, marketing and management.
              </p>
              <p className="sm-text">
                He holds a Masters in Engineering and an MBA from University of South Florida.
              </p>
            </div>
          </div>
          <div className={Styled.card}>
            <header className={Styled.header}>
              <figure className={Styled.figure}>
                <img alt="Timothy Boylan" src={Profile2}></img>
                {/* <caption>
                  <SocialIcons />
                </caption> */}
              </figure>
              <div className={Styled.cardTitleWrapper}>
                <h1 className="sm-title bold">Timothy Boylan</h1>
                <h3 className="sm-text">
                  Executive Vice President Sales & Marketing, USA
                </h3>
              </div>
            </header>
            <div className={Styled.textWrapper}>
              <p className="sm-text">
                Responsible for overall global sales and marketing strategy,
                with a particular emphasis on business development in the USA
                and Canada
              </p>

              <p className="sm-text">
                Charged with managing all client services and retention efforts
              </p>
              <p className="sm-text">
                20+ years of executive level expertise in multiple industries
                with early stage to mature companies
              </p>
              <p className="sm-text">
                Strong entrepreneurial background. Founded, operated, and
                successfully sold Human-i-Tees, a $45 million direct marketing
                business that helped K-12 schools with fundraising
              </p>
            </div>
          </div>
          <div className={Styled.card}>
            <header className={Styled.header}>
              <figure className={Styled.figure}>
                <img alt="Rajesh Velayuthasamy" src={Profile5}></img>
                {/* <caption>
                  <SocialIcons />
                </caption> */}
              </figure>
              <div className={Styled.cardTitleWrapper}>
                <h1 className="sm-title bold">Rajesh Velayuthasamy</h1>
                <h3 className="sm-text">CMO</h3>
              </div>
            </header>
            <div className={Styled.textWrapper}>
              <p className="sm-text">
                Heads the European division of the company. He works from our
                London, UK office and is responsible for driving Integra's sales
                and marketing efforts .
              </p>
              <p className="sm-text">
                He has 15+ years of experience in working on the forefront of
                technology and service innovations in the outsourcing industry
              </p>
              <p className="sm-text">
                His prior work experience spans across companies such as Satyam,
                GE, Wipro, Nokia and Panasonic, with implementing several
                successful projects with multiple global corporations in Europe
                and Asia
              </p>
            </div>
          </div>
          <div className={Styled.card}>
            <header className={Styled.header}>
              <figure className={Styled.figure}>
                <img alt="Aravind Kumar" src={Profile6}></img>
                {/* <caption>
                  <SocialIcons />
                </caption> */}
              </figure>
              <div className={Styled.cardTitleWrapper}>
                <h1 className="sm-title bold">Aravind<br /> Kumar</h1>
                <h3 className="sm-text">COO</h3>
              </div>
            </header>
            <div className={Styled.textWrapper}>
              <p className="sm-text">
                His hands on experience at the bottom, middle and top level
                management has brought tremendous growth to the company and
                success to our clients
              </p>
              <p className="sm-text">
                More than 15+ years of experience in working with internet and
                web based support with clients across continents and in several
                industries
              </p>
              <p className="sm-text">
                Prior experience in working with large companies such as Yahoo
                India, Rediff and Honda to manage several projects
              </p>
              <p className="sm-text">
                Has a Bachelor's degree in Accounting and Corporate Law and a
                Master's degree in International Business
              </p>
            </div>
          </div>
          <div className={Styled.card}>
            <header className={Styled.header}>
              <figure className={Styled.figure}>
                <img alt="Ram Prakash" src={Profile7}></img>
                {/* <caption>
                  <SocialIcons />
                </caption> */}
              </figure>
              <div className={Styled.cardTitleWrapper}>
                <h1 className="sm-title bold">Ram <br /> Prakash</h1>
                <h3 className="sm-text">CFO</h3>
              </div>
            </header>
            <div className={Styled.textWrapper}>
              <p className="sm-text">
                His focus on quality and motivation is the primary reason why we
                have dozens of satisfied clientele
              </p>
              <p className="sm-text">
                Responsible for the leadership and execution of the operations
                at our branch in India
              </p>
              <p className="sm-text">
                15+ years of experience in management, human resources and
                outsourcing
              </p>
              <p className="sm-text">
                He has a Bachelor's in Accounting and Company Law and an MBA
                with a focus in management
              </p>
            </div>
          </div>
          <div className={Styled.card}>
            <header className={Styled.header}>
              <figure className={Styled.figure}>
                <img alt="David Highbloom" src={Profile4}></img>
                {/* <caption>
                  <SocialIcons />
                </caption> */}
              </figure>
              <div className={Styled.cardTitleWrapper}>
                <h1 className="sm-title bold">Ben Roberts</h1>
                <h3 className="sm-text">Sr. VP Client Relations USA</h3>
              </div>
            </header>
            <div className={Styled.textWrapper}>
              <p className="sm-text">
                Responsible for managing client relationships and proactively
                offering value added solutions to improve processes and
                performance.
              </p>
              <p className="sm-text">
                10+ years experience as a strategic and operational executive in
                technology-enabled environments that have evolved rapidly and
                achieved significant size.
              </p>
              <p className="sm-text">
                Ben holds a B.A. in Math & Economics from Wesleyan University.
              </p>
            </div>
          </div>
          <div className={Styled.card}>
            <header className={Styled.header}>
              <figure className={Styled.figure}>
                <img alt="David Highbloom" src={Profile3}></img>
                {/* <caption>
                  <SocialIcons />
                </caption> */}
              </figure>
              <div className={Styled.cardTitleWrapper}>
                <h1 className="sm-title bold">David Highbloom</h1>
                <h3 className="sm-text">Sr. VP Business Development USA</h3>
              </div>
            </header>
            <div className={Styled.textWrapper}>
              <p className="sm-text">
                Responsible for organizing and managing cross functional teams
                in human resources, training, operations, finance and
                information technology in the pursuit of new business
                relationships for the organization.
              </p>
              <p className="sm-text">
                Expert knowledge of on-shore, near-shore and off-shore
                omni-channel delivery locations with over 30+ years experience
                building, scaling and ultimately selling businesses across
                multiple industry verticals.
              </p>
              <p className="sm-text">
                David Highbloom has a B.A. in Communications from Tulane
                University.
              </p>
              <p className="sm-text">
                He has a Masters in Engineering with an MBA from Florida
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission

import React from "react"
import HeroBg from "../../../assets/img/hero-photo-03.png"
import HeroBg2 from "../../../assets/img/hero-photo-03.png"
import Partner1 from "../../../assets/img/clients/nasscom.png"
import Partner2 from "../../../assets/img/clients/pci-security-standard.png"
import Partner3 from "../../../assets/img/clients/google-partner.png"
import Partner4 from "../../../assets/img/clients/uipath.png"
import Partner5 from "../../../assets/img/clients/top.png"
import PathIcon from "../../../components/Icon/path"
import ISO from "../../../assets/img/clients/iso-27001-3.png"

import Styled from "./styled.module.css"

function Hero() {
  return (
    <section>
      <div className={Styled.heroWrapper}>
        <img
          alt="Hero Background"
          srcSet={`${HeroBg2} 500w, ${HeroBg} 1400w,  ${HeroBg} 1500w `}
          className={Styled.heroBg}
        ></img>
      </div>
      <div className={`${Styled.sectionWrapper} container  `}>
        <div className={`${Styled.titleWrapper} hidden-upto-md`}>
          <h1 className={`${Styled.partnerTitle} sm-title`}>
            Partners & Certificates
          </h1>
          <div className={Styled.partnerWrapper}>
            <div className={Styled.partnerGroup}>
              <img
                alt="Partner"
                className={Styled.partnerImg}
                src={Partner3}
              ></img>
              <img alt="Partner" className={Styled.partnerImg} src={ISO}></img>
            </div>
            <div className={Styled.partnerGroup}>
              <img
                alt="Partner"
                className={Styled.partnerImg}
                src={Partner2}
              ></img>
              <img alt="Partner" className={Styled.partnerImg} src={Partner4}></img>
            </div>
            <div className={Styled.partnerGroup}>
              <img
                alt="Partner"
                className={Styled.partnerImg}
                src={Partner1}
              ></img>
              <img alt="Partner" className={Styled.partnerImg} src={Partner5}></img>
            </div>
          </div>
        </div>
        <div className={Styled.relative}>
          <div className={Styled.cardWrapper}>
            <h1 className={`${Styled.title} sm-title bold`}>
              Delivering digital<br /> operations since 2004
            </h1>
            <p className="sm-text">
              Integra grows consistently in both business and areas of expertise. We have done this by responding to our clients rather than reacting to them. Today we focus on providing digital solutions for global problems.
            </p>
            <p className="sm-text">
              Our ongoing evolution has taken us from being a KPO company to adding on new technology areas including Robotic Automation, IT Software and SaaS Products.
            </p>
            <p className="sm-text">
              Our idea is to empower businesses of all sizes with digital solutions for daily problems.
            </p>
            {/* <span className={Styled.linkWrapper}>
              <a href="/#" className={`${Styled.linkIcon} sm-text`}>
                Watch videos
                <PathIcon />
              </a>
            </span> */}
          </div>
        </div>
      </div>
      <div
        className={`${Styled.sectionWrapperMobile} container hidden-from-md`}
      >
        <div className={Styled.titleWrapper}>
          <h1 className={`${Styled.partnerTitle} sm-title`}>
            Partners & Certificates
          </h1>
          <div className={Styled.partnerWrapper}>
            <div className={Styled.partnerGroup}>
              <img
                alt="Partner"
                className={Styled.partnerImg}
                src={Partner3}
              ></img>
              <img alt="Partner" className={Styled.partnerImg} src={ISO}></img>
            </div>
            <div className={Styled.partnerGroup}>
              <img
                alt="Partner"
                className={Styled.partnerImg}
                src={Partner2}
              ></img>
              <img alt="Partner" className={Styled.partnerImg} src={ISO}></img>
            </div>
            <div className={Styled.partnerGroup}>
              <img
                alt="Partner"
                className={Styled.partnerImg}
                src={Partner1}
              ></img>
              <img alt="Partner" className={Styled.partnerImg} src={ISO}></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero

import React from "react"
import RightArrow from "../../Icon/Arrow/right-arrow-circle"
import RoboticLogo from "../../../assets/img/robotic-logo.svg"
import WebservicesLogo from "../../../assets/img/webservices-logo.svg"
import BookkeepingLogo from "../../../assets/img/bookkeeping-logo.svg"
import Styled from "./styled.module.css"
import { Link } from "gatsby"

const Mission = () => {
  return (
    <section className={Styled.section}>
      <div className={`container ${Styled.grid}`}>
        <div className={Styled.wrapper}>
          <div className={Styled.titleWrapper}>
            <h2 className="sm-text bold">OUR SERVICES</h2>
            <h1 className="md-title bold">
              Powerful services that plugin to your business
            </h1>
            <p className="md-text">
              Our service delivery teams are guided by a process driven approach
              built on top of bot driven applications.
            </p>
          </div>
          <Link to="/services" className="explore xs-text bold">
            Explore our services
          </Link>
        </div>
        <div className={Styled.grid}>
          <div className={Styled.numberWrapper}>
            <div className={Styled.number}>
              <img alt="Robotic Logo" src={RoboticLogo}></img>
            </div>
            <div className={Styled.number}>
              <img alt="Bookkeeping Logo" src={BookkeepingLogo}></img>
            </div>
            <div className={Styled.number}>
              <img alt="Webservices Logo" src={WebservicesLogo}></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission

import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/"
import Hero from "../components/AboutPage/Hero"
import Industries from "../components/Industries"
import Mission from "../components/AboutPage/Mission"
import Leader from "../components/AboutPage/Leader"
import Services from "../components/AboutPage/Services"
import Values from "../components/AboutPage/Values"
import Organisms from "../components/Organisms"
import Trusted from "../components/Trusted"
import "../assets/css/normalize.css"
import "../assets/css/custom.css"
import FeaturedImage from "../assets/img/integra-featured-image.png"

export default function Home() {
  return (
    <>
      <Helmet
        title="We provide business services companies in US, UK, Europe, Canada and Australia"
        meta={[
          {
            name: `description`,
            content:
              "Need help to support and grow your business? Don't look elsewhere, we are a business service provider for small medium and large companies. Integra Global Solutions provides services to business across US, UK, Canada, Europe, Australia and Asian countries. Our local US and UK presence with offices in India allows us to provide high quality, low cost outsourcing services",
          },
          {
            name: "keywords",
            content:
              "Business services, small business support, about us, Integra, About Integra, Integra Global Solutions, business support, business outsourcing services, outsourcing services, small business services, medium and large business services",
          },
        ]}
      >
        <meta property="og:url" content="https://www.globalintegra.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="We provide business services companies in US, UK, Europe, Canada and Australia"
        />
        <meta
          property="og:description"
          content="Need help to support and grow your business? Don't look elsewhere, we are a business service provider for small medium and large companies. Integra Global Solutions provides services to business across US, UK, Canada, Europe, Australia and Asian countries. Our local US and UK presence with offices in India allows us to provide high quality, low cost outsourcing services"
        />
        <meta property="og:image" content={FeaturedImage} />

        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="globalintegra.com" />
        <meta property="twitter:url" content="https://www.globalintegra.com/" />
        <meta
          name="twitter:title"
          content="We provide business services companies in US, UK, Europe, Canada and Australia"
        />
        <meta
          name="twitter:description"
          content="Need help to support and grow your business? Don't look elsewhere, we are a business service provider for small medium and large companies. Integra Global Solutions provides services to business across US, UK, Canada, Europe, Australia and Asian countries. Our local US and UK presence with offices in India allows us to provide high quality, low cost outsourcing services"
        />
        <meta name="twitter:image" content={FeaturedImage}></meta>
      </Helmet>
      <Layout>
        <main>
          <Hero />
          <Mission />
          <Values />
          <Leader />
          <Services />
          <Industries />
          <Organisms />
          <Trusted />
        </main>
      </Layout>
    </>
  )
}

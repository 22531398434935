import React from "react"
import { Link } from "gatsby"
import Styled from "./styled.module.css"
import Button from "../../Button"
const Mission = () => {
  return (
    <section className={Styled.section}>
      <div className={`container ${Styled.grid}`}>
        <div className={Styled.wrapper}>
          <div className={Styled.titleWrapper}>
            <h2 className="sm-text bold">WE ARE</h2>
            <h1 className="md-title bold">
              Good people making a positive difference to companies worldwide
            </h1>
            <p className="md-text">
              We work with small and medium businesses across USA, Canada, UK, Germany, Nedherlands, Europe and Australia.
              We partner with them to achieve high performance through tailored technology driven outsourcing solutions.
            </p>
          </div>
          <div className={Styled.buttonWrapper}>
            <a target="_blank" href="/Integra_Corporate_Profile.pdf">
              <Button>
                <span>Download Corporate PDF</span>
              </Button>
            </a>
          </div>
        </div>
        <div className={Styled.grid}>
          <div className={Styled.numberWrapper}>
            <div className={Styled.number}>
              <h1 className="md-title bold">16</h1>
              <p className="md-text">Years of experience</p>
            </div>
            <div className={Styled.number}>
              <h1 className="md-title bold">5</h1>
              <Link to="/contact" className={`${Styled.offices} md-text`}>
                Offices
              </Link>
            </div>
            <div className={Styled.number}>
              <h1 className="md-title bold">1300+</h1>
              <p className="md-text">Employees</p>
            </div>
            <div className={Styled.number}>
              <h1 className="md-title bold">1270+</h1>
              <p className="md-text">Clients served each year</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission

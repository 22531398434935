import React from "react"
import ValuesImg from "../../../assets/img/values.png"
import Styled from "./styled.module.css"
const Mission = () => {
  return (
    <section className={Styled.section}>
      <div className="container">
        <div className={Styled.sectionWrapper}>
          <img alt="Values" className={Styled.img} src={ValuesImg}></img>
          <div className={Styled.titleWrapper}>
            <h1 className="md-title bold">
              We’re driven
              <br /> by our core values
            </h1>
            <ul className={Styled.listWrapper}>
              <li className={`${Styled.listTitle} sm-title bold`}>Be good.</li>
              <li className="sm-text">Ensure client happiness</li>
              <li className="sm-text">Equality in every area </li>
              <li className="sm-text">Mutual respect and trust</li>
            </ul>
            <ul className={Styled.listWrapper}>
              <li className="sm-title bold">Do good.</li>
              <li className="sm-text">Can-do attitude</li>
              <li className="sm-text">Relentless quality focus</li>
              <li className="sm-text">Accountability</li>
            </ul>
            <ul className={Styled.listWrapper}>
              <li className="sm-title bold">Feel good.</li>
              <li className="sm-text">Be proud of achivements</li>
              <li className="sm-text">Personal development</li>
              <li className="sm-text">Team spirit</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission

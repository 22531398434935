import React from "react"

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      fill="none"
      viewBox="0 0 54 54"
      {...props}
    >
      <path
        fill="url(#paint0_linear)"
        d="M0 27C0 12.088 12.088 0 27 0s27 12.088 27 27-12.088 27-27 27S0 41.912 0 27z"
      ></path>
      <path
        stroke="#FCFCFC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24 20l6.963 6.963L24 33.926"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          x2="65.096"
          y1="0"
          y2="29.585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#624AF2"></stop>
          <stop offset="1" stopColor="#50DDC3"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon

import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={76}
      height={20}
      viewBox="0 0 76 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.547 17v-5.92h2.074l.308-2.318h-2.382V7.285c0-.669.194-1.127 1.19-1.127H13V4.092A17.379 17.379 0 0011.15 4C9.318 4 8.06 5.078 8.06 7.056v1.701H6v2.318h2.064V17h2.483zM43.123 4H32.877a.849.849 0 00-.877.877v10.246c0 .462.415.877.877.877h10.246c.508 0 .877-.37.877-.877V4.877A.876.876 0 0043.123 4zm-7.43 10.154h-1.847V8.615h1.846v5.539zm-.924-6.277c-.6 0-1.108-.508-1.108-1.108 0-.6.508-1.107 1.108-1.107.6 0 1.108.507 1.108 1.107s-.508 1.108-1.108 1.108zm7.385 6.277h-1.846v-2.77c0-.738-.185-1.476-.923-1.476-.739 0-.923.738-.923 1.477v2.769h-1.847V8.615h1.846v.647h.093c.23-.462.83-.831 1.523-.831 1.708 0 2.077 1.107 2.077 2.492v3.23zM63.775 15a6.81 6.81 0 002.687-.535 6.949 6.949 0 002.278-1.556 7.152 7.152 0 001.517-2.336c.35-.874.527-1.81.521-2.756v-.33c.478-.358.891-.8 1.222-1.303a4.98 4.98 0 01-1.413.397 2.54 2.54 0 001.091-1.398 4.778 4.778 0 01-1.56.615 2.447 2.447 0 00-1.396-.764 2.412 2.412 0 00-1.563.264 2.504 2.504 0 00-1.083 1.184 2.588 2.588 0 00-.158 1.615 6.84 6.84 0 01-2.81-.763 7.012 7.012 0 01-2.262-1.871 2.579 2.579 0 00-.265 1.832c.142.623.51 1.168 1.028 1.524a2.392 2.392 0 01-1.09-.313v.027c-.004.58.186 1.143.538 1.596.353.453.846.768 1.398.894a2.413 2.413 0 01-1.091.045c.16.494.464.925.872 1.234.409.31.9.482 1.408.494a4.861 4.861 0 01-3.06 1.119 5.186 5.186 0 01-.584-.05 6.839 6.839 0 003.775 1.118"
        fill="#6E7191"
      />
    </svg>
  )
}

export default SvgComponent

import React, { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import { navigate } from "gatsby"

import Styled from "./styled.module.css"

function ContactForm() {
  const { register, errors, handleSubmit } = useForm()

  const [state, setState] = useState({
    error: false,
    errorMessage: "",
    checkOnSubmit: false,
  })
  const request_url = `${process.env.GATSBY_CONTACT_PATH}`

  const onSubmit = data => {
    setState({
      checkOnSubmit: !state.checkOnSubmit,
    })
    axios({
      method: "post",
      url: request_url, //change this to correct endpoint
      headers: { "content-type": "application/json" },
      data: JSON.stringify(data),
    })
      .then(result => {
        if (result.data.sent) {
          navigate("/thankyou/")
        } else {
          setState({
            error: !state.error,
            errorMessage: result.data.message,
          })
        }
      })
      .catch(error => "")
  }

  return (
    <div className={Styled.formWrapper}>
      <div
        className={`${state.checkOnSubmit ? "f-submitting" : "f-notsubmitted"}`}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={Styled.formContact}
          action="#"
        >
          <div className={Styled.formGroup}>
            <input
              className={Styled.inputContact}
              placeholder="Name"
              type="text"
              name="name"
              ref={register({
                required: "Name should not be empty",
                minLength: {
                  value: 3,
                  message: "Name should be at least 3 characters long",
                },
                maxLength: {
                  value: 50,
                  message: "Name should not be more than 50 characters long",
                },
              })}
            ></input>
            {errors.name && (
              <span className="error_message">{errors.name.message}</span>
            )}
          </div>
          <div className={Styled.formGroup}>
            <input
              className={Styled.inputContact}
              placeholder="Company"
              type="text"
              name="company"
              ref={register({
                required: "Company name should not be empty",
                minLength: {
                  value: 3,
                  message: "Company name should be at least 3 characters long",
                },
                maxLength: {
                  value: 50,
                  message:
                    "Company name should not be more than 50 characters long",
                },
              })}
            ></input>
            {errors.company && (
              <span className="error_message">{errors.company.message}</span>
            )}
          </div>
          <div className={Styled.formGroup}>
            <input
              className={Styled.inputContact}
              placeholder="Email"
              type="email"
              name="contactemail"
              ref={register({
                required: "Email should not be empty",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email should be valid",
                },
              })}
            ></input>
            {errors.contactemail && (
              <span className="error_message">
                {errors.contactemail.message}
              </span>
            )}
          </div>
          <div className={Styled.formGroup}>
            <input
              className={Styled.inputContact}
              placeholder="Phone"
              type="text"
              name="phone"
              ref={register({
                required: "Phone number should not be empty",
                pattern: {
                  value: /^[0-9]\d*\.?\d*$/i,
                  message: "Phone number should be valid",
                },
              })}
            ></input>
            {errors.phone && (
              <span className="error_message">{errors.phone.message}</span>
            )}
          </div>
          <div className={Styled.formGroup}>
            <textarea
              className={Styled.textArea}
              placeholder="Requirements"
              rows="5"
              type="text"
              name="req"
              ref={register({
                required: "Requirement should not be empty",
                minLength: {
                  value: 3,
                  message: "Requirement should be at least 3 characters long",
                },
                maxLength: {
                  value: 500,
                  message:
                    "Requirement should not be more than 500 characters long",
                },
              })}
            ></textarea>
            {errors.req && (
              <span className="error_message">{errors.req.message}</span>
            )}
            {state.error && (
              <div className="error_div">{state.errorMessage}</div>
            )}
          </div>
          <footer>
            {/* <label className={`${Styled.checkboxLabel} xs-text`} for="copy">
                <input
                  className={Styled.inputCheckbox}
                  id="copy"
                  type="checkbox"
                />
                Send me a copy
              </label> */}
            <button type="submit" className={Styled.inputSubmit}>
              {!state.checkOnSubmit && <>Send Details</>}

              {state.checkOnSubmit && (
                <div className={Styled.flexCenter}>
                  Sending
                  <div className={Styled.ldsRing}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}

              {/* {!state.checkOnSubmit ? "Send Details" : "Sending..."}
                        <div className={Styled.ldsRing}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div> */}
            </button>
          </footer>
        </form>
      </div>
    </div>
  )
}

export default ContactForm

import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={10}
      height={15}
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.456.89A.285.285 0 000 1.122v12.754a.29.29 0 00.286.29c.06 0 .12-.02.17-.056L9.05 7.734a.292.292 0 000-.468L.456.889z"
        fill="#258AFF"
      />
    </svg>
  )
}

export default SvgComponent

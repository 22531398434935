import React from "react"
import PropTypes from "prop-types"

import Styled from "./styled.module.css"

function Button(props) {
  return (
    <button
      className={`${Styled.button} ${
        props.secondary ? Styled.buttonSecondary : ""
      } ${props.subtle === "white" ? Styled.buttonSubtleWhite : ""} 
      ${props.subtle === "primary" ? Styled.buttonSubtlePrimary : ""}
      ${props.size === "small" ? Styled.buttonSmall : ""} ${
        props.size === "medium" ? Styled.buttonMedium : ""
      } `}
      {...props}
    >
      <span>{props.children}</span>
    </button>
  )
}

Button.defaultProps = {
  secondary: null,
}

Button.propTypes = {
  size: PropTypes.string,
  subtle: PropTypes.string,
  secondary: PropTypes.bool,
}
export default Button

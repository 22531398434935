import React from "react"
import { Link } from "gatsby"
import Styled from "./trusted.module.css"
import Button from "../Button"

import ContactForm from "../ContactPage/Form"



function Trusted() {
  return (
    <section className={Styled.section} id="trusted">
      <div className={`container ${Styled.trustedWrapper}`}>
        <div className={Styled.trustedWrapperContainer}>
          <div className={Styled.trustedDescription}>
            <h1 className={`${Styled.title} lg-title bold`}>
              We are your <br />
          trusted partner
        </h1>
            <h2 className={`${Styled.text} md-text`}>
              From global enterprises to booming startups, business owners count on
              us. We are flexible & scalable to meet your unique outsourcing needs.
        </h2>
            <Link to="/contact">
              <Button size="large">Talk to a consultant</Button>
            </Link>
          </div>
          <ContactForm></ContactForm>
        </div>
      </div>
    </section>
  )
}

export default Trusted
